export const parsePayload = (payload) => {
	try {
        const payloadDecode = atob(payload);
        const payloadObj = JSON.parse(payloadDecode);
		if (typeof payloadObj === 'object'){
           return payloadObj;
        }
	} catch (ex){
		return false;
	}
}
