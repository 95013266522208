<template>
    <div>        
            <BaseLayout>    
                
                    <b-overlay :show="preLoading" opacity="0.8"

                        variant="transparent"                    
                        blur="2rem"                    
                    >                        
                    <div class="container login-wrap">            
                            <div v-if="!error">
                                <form ref="form">
                                    <input type="hidden" name="hotspotDataHash" ref="hotspotDataHash" />
                                    <input type="hidden" name="guest_relation_id" ref="guest_relation_id" />
                                </form>
                                <div v-if="!guests.length">
                                    <h6>{{ $t('login.entre-com-os-dados-da-reserva') }}</h6>
                                    <b-row>
                                        <b-col cols="8">{{ $t('login.sobrenome') }}</b-col>
                                        <b-col cols="4">{{ $t('login.n-quarto') }}</b-col>
                                    </b-row>
                                    <b-form-group>
                                        <b-row>
                                            <b-col cols="8">
                                                <b-form-input
                                                    :placeholder="$t('login.sobrenome-placeholder')"
                                                    v-model.lazy="$v.last_name.$model"
                                                ></b-form-input>
                                                <div class="errors" v-if="$v.last_name.$error">
                                                    <div class="vuelidate-error" v-if="!$v.last_name.required">{{$t('login.requer-sobrenome')}}</div>
                                                </div>
                                            </b-col>
                                            <b-col cols="4">
                                                <b-form-input
                                                    :placeholder="$t('login.n-quarto-placeholder')"
                                                    v-model.lazy="$v.room_number.$model"
                                                ></b-form-input>
                                                <div class="errors" v-if="$v.room_number.$error">
                                                    <div class="vuelidate-error" v-if="!$v.room_number.required">{{$t('login.requer-n-quarto')}}</div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                    <div>
                                        <b-button block variant="primary" @click="login">Entrar</b-button>
                                        <b-button block variant="secondary" @click="back">Voltar</b-button>
                                    </div>
                                </div>
                                <div v-else>
                                    
                                        <div>
                                            <p>{{$t('login.qual-dos-hospedes')}}</p>
                                            <b-list-group v-show="guests.length">
                                                <b-list-group-item
                                                    v-for="guest in guests"
                                                    v-bind:key="guest.id"
                                                    @click="continueLogin(guest)"
                                                    class="guest-list-item"
                                                    >{{ guest.first_name }}</b-list-group-item
                                                >
                                            </b-list-group>
                                            <p>&nbsp;</p>
                                            <b-button block variant="danger" @click="cancelLogin" class="cancel">Cancelar</b-button>
                                        </div>
                                    
                                </div>
                            </div>
                            <div v-else>
                                <h1>Error</h1>
                            </div>            
                    </div>                        
                    </b-overlay>
                
            </BaseLayout>            
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { parsePayload } from '../libraries/helpers.js';
import BaseLayout from '@/components/BaseLayout';

export default {
    components: {
      BaseLayout
    },
    mounted () {
        //console.log('payload2: ' + this.$route.query.payload);      
        if (this.$route.query.payload !== undefined) {
            const payloadObject = parsePayload(this.$route.query.payload);
            if (payloadObject) {
                this.hotel_code = payloadObject.hotel_code;
                this.error = false;
            } else {
                this.error = true;
            }
        } else {
            this.error = true;
        }

        if (this.$route.query.hotspotDataHash !== undefined) {
            this.hotspotDataHash = this.$route.query.hotspotDataHash;
        } else {
            this.error = true;
        }        
    },
    data() {
        return {
            preLoading: false,
            last_name: "",
            room_number: "",
            hotel_code: false,
            hotspotDataHash: false,
            guests: [],
            error: false
        };
    },
    methods: {
        continueLogin(guest) {
            this.$refs.form.setAttribute(
                "action",
                window.location.protocol + "//" + process.env.VUE_APP_WISPOT_HOST + "/integration/spothotel"
            );
            this.$refs.form.setAttribute("method", "POST");

            this.$refs.hotspotDataHash.setAttribute("value", this.hotspotDataHash);
            this.$refs.guest_relation_id.setAttribute("value", btoa(guest.id));

            this.$refs.form.submit();
        },
        async login() {
            const instance = this;
            this.$v.$touch();
            if (!this.$v.last_name.$invalid && !this.$v.room_number.$invalid) {
                const baseURI = window.location.protocol + "//" + process.env.VUE_APP_API + "/api/login";
                this.preLoading = true;
                await this.$http
                    .post(baseURI, {
                        hotel_code: this.hotel_code,
                        room_number: this.room_number,
                        last_name: this.last_name
                    })
                    .then(result => {
                        const data = result.data.data;
                        if (data != null && data.length) {
                            if (data.length > 1) {
                                instance.guests = data;
                            } else {
                                const guest = data[0];
                                this.continueLogin(guest);
                            }
                            console.log(instance.guests);
                        } else {
                            this.$toast.info(instance.$t('login.no-checkin'));
                        }
                        instance.preLoading = false;
                    }).catch(result => {
                        instance.$toast.error("contact the administrator");
                        instance.preLoading = false;
                    });
            }
        },
        back(){
             window.history.back();
        },
        cancelLogin(){
            this.guests = [];
        } 
    },
    validations: {
        last_name: { required },
        room_number: { required }
    }
};
</script>

<style>
.container.login-wrap {    
    padding: 1em;
    background-color: #fff;
}

.logo {
    display: block;
    width: 50px;
    margin: 0 auto;
}

.errors .error {
    color: brown;
}

.guest-list-item {
    cursor: pointer;
}
</style>
