<template>
  <div>
      <v-style>{{backgroundImage}}</v-style>

      <div class="container-fixed-bottom">
        <div class="container-centered">
          <slot></slot>
        </div>
      </div>
      
  </div>
</template>

<style></style>

<script>

import { parsePayload } from '../libraries/helpers.js'

export default {  
  mounted(){
    //console.log('payload: ' + this.$route.query.payload);  
    
    if (this.$route.query.payload !== undefined) {
       const payloadObject = parsePayload(this.$route.query.payload);
       if (payloadObject) {
          const background = window.location.protocol + "//" + process.env.VUE_APP_WISPOT_HOST + "/assets/uploads/screens/" + payloadObject.background;          
          this.background = background;   
          document.getElementById('pagetitle').innerHTML = 'Bem-vindo(a) ao ' + payloadObject.customer_name;    
       }
    }   
  },
  data() {
      return {
           background: ''
      }
  },
  computed: {
      backgroundImage() {
        if (this.background !== ''){
          return 'body{background-image:url("' + this.background + '");background-repeat: no-repeat;background-position: top center;background-attachment: fixed;-webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-size: cover;}';
        } else {
          return '';
        }       
      }
    }
}
</script>
